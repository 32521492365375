import React from 'react'
import { useEffect } from 'react'
import { useState } from 'react'
import { matchPath, useHistory } from 'react-router-dom'
import { useForm, Controller } from 'react-hook-form'
import Container from '../../components/container/container'
import Navigation from '../../components/navigation/navigation'
import request from '../../helpers/request'
import { useMemo } from 'react'
import Select from 'react-select'
// import { handleInputChange } from 'react-select/src/utils'

export default function DataFilter() {
  const history = useHistory();
  const match = matchPath(history.location.pathname, {
    path: '/admin/portal/filters/:id'
})

  const [filter, setFilter] = useState({})
  const [realProducts, setRealProducts] = useState([])
  const {register,handleSubmit,control,setValue,getValues} = useForm()

  const filterId = useMemo(() => {
    return match.params.id
  }, [match.params.id])

  const getDataFilter = (id = filterId) => {
    request(true).get(`/datafilters/${id}`).then(data => {
      if (data.status === 200) {
        setFilter(data.data)
      }
    })
  }

  // const getDataFilterOptions = () => {
  //   request(true).get
  // }

  const getRealProducts = () => {
    request(true).get('/real-products').then(data => {
      if (data.status === 200) setRealProducts(data.data)
    })
  }

  const realProductSelect = useMemo(() => {
    return realProducts.map(_ => {
      return {
        value: _.id,
        label: _.product_key
      }
    })
  }, [realProducts])

  const createOrUpdate = (data) => {
    if (filterId !== 'new') {
      request(true).post(`/datafilters/${filterId}`, data).then(e => {
        if (e.status === 200) {
          alert(e.statusText)
        }
      }).catch(e => {
        if (e && e.response) {
          window.alert(JSON.stringify(e.response.data))
        }
      })
    } else {
      request(true).post(`/datafilters`, data).then(e => {
        alert(e.statusText)
        if (e.status === 201) {
          window.location.replace(`/admin/portal/filters/${e.data.id}`)
        }
      }).catch(e => {
        if (e && e.response) {
          window.alert(JSON.stringify(e.response.data))
        }
      })
    }
    console.log(data)
  }

  useEffect(() => {
    if (filterId !== 'new') {
      getDataFilter(filterId);
    }
    getRealProducts()
  }, [])

  return (
    <div className="grid grid-gap-20">
      <Navigation />
      {
        filter.id || filterId === 'new' ? <>
        <Container>
            <div className="flex middle">
                <p className="colour-secondary font-weight-600" style={{fontSize: 20}}>Data Filter {filterId !== 'new' ? ` - "${filter.internal_name}"` : null}</p>
            </div>
        </Container>
        <Container>
          <div className="grid grid-columns-2 grid-gap-15">
            <form onSubmit={handleSubmit(createOrUpdate)}>
              <p className="colour-secondary font-weight-600" style={{fontSize: 18}}>Filter</p>
              <div className="form-row">
                <label>Internal Name (Not shown to customer)</label>
                <input ref={register({required: true})} defaultValue={filter.id ? filter.internal_name : undefined} name="internal_name" className="input" type="text" required />
              </div>
              <div className="form-row">
                <label>Title (Shown to customer)</label>
                <input ref={register({required: true})} defaultValue={filter.id ? filter.title : undefined} name="title" className="input" type="text" required />
              </div>
              <div className="form-row">
                <label>Target Column (Column to compare values)</label>
                <input ref={register({required: true})} defaultValue={filter.id ? filter.target_column : undefined} name="target_column" className="input" type="text" required />
              </div>
              {/* <div className="form-row">
                <label>Function Name (For complex logic)</label>
                <input ref={register({required: false})} defaultValue={filter.id ? filter.functionName : undefined} name="functionName" className="input" type="text" />
              </div>
              <div className="form-row">
                <label>Can be changed by user?</label>
                <input ref={register()} defaultValue={filter.id ? filter.customisable : undefined} name="customisable" className="input" type="checkbox" />
              </div> */}
              <div className="form-row">
                <label>Row Length (Amount of options in a row)</label>
                <input ref={register({valueAsNumber: true})} defaultValue={filter.id ? filter.row_length : undefined} name="row_length" className="input" type="number" max={12} min={1} />
              </div>
              <div className="form-row">
                <label>Order Weight (Higher for first)</label>
                <input ref={register({valueAsNumber: true})} defaultValue={filter.id ? filter.order : undefined} name="order" className="input" type="number" min={0} />
              </div>
              {/* <div className="form-row">
                <label>Default for every comapny?</label>
                <input ref={register()} defaultValue={filter.id ? filter.default : undefined} name="default" className="input" type="checkbox" />
              </div> */}
              <div className="form-row">
                <label>Real Product</label>
                {/* <input ref={register({required: true})} defaultValue={filter.id ? filter.real_product_id : undefined} name="real_product_id" className="input" type="number" required /> */}
                {/* <Select 
                name="real_product_id" 
                defaultValue={filter.id ? filter.real_product_id : undefined} 
                options={realProductSelect} 
                isLoading={!realProductSelect || !realProductSelect.length} 
                /> */}
                <Controller
                name="real_product_id" 
                control={control}
                defaultValue={filter.real_product_id || undefined}
                render={props => 
                  <Select
                    options={realProductSelect} 
                    value={realProductSelect.find(_ => _.value === filter.real_product_id)} 
                    isLoading={!realProductSelect || !realProductSelect.length} 
                    onChange={_ => { props.onChange(_.value); setFilter({...filter, real_product_id: _.value}) }}
                  />
                }
                />
              </div>
              {/* <div>
                <label>Company (optional)</label>
                <Controller
                  name="company_id"
                  control={control}
                  render={props => 
                    <CompanySelect onChange={_ => { props.onChange(_.value); setFilter({...filter, company_id: _.value}) }} value={filter.company_id} />
                  } />
              </div> */}
              <button className="button compact background-primary colour-white" type="submit">{filterId === 'new' ? 'Create Data Filter' : 'Update Data Filter'}</button>
            </form>
            <div>
              <div>
                <p className="colour-secondary font-weight-600" style={{fontSize: 18}}>Filter Options</p>
              </div>
              <br />
              <br />
              <div className="grid grid-columns-2">
                {
                  filter.options && filter.options.length ? <>
                    {
                      filter.options.map(option => {
                        return <DataFilterOption key={`data_filter_${option.id}`} reload={getDataFilter} filterId={filterId} option={option} />
                      })
                    }
                  </> : null
                }
                <DataFilterOption key="new_data_filter_option" reload={getDataFilter} filterId={filterId} option={{}} />
              </div>
            </div>
          </div>
          </Container>
        </> : <Container>
          <p className="colour-secondary font-weight-600" style={{fontSize: 20}}>Loading...</p>
        </Container>
      }
    </div>
  )
}


const DataFilterOption = (props) => {
  const updateOption = (data) => {
    if (props.option.id) {
      return request(true).post(`/datafilters/${props.filterId}/options/${props.option.id}`, {...data, data_filter_id: props.filterId}).then(e => {
        if (e.status === 200) {
          props.reload();
          return alert(e.statusText)
        }
      })
    }

    request(true).post(`/datafilters/${props.filterId}/options`, {...data, data_filter_id: props.filterId}).then(e => {
      if (e.status === 201) {
        props.reload();
        return alert(e.statusText)
      }
    })
  }

  const deleteOption = (e) => {
    e.preventDefault();
    const deleteThis = window.confirm('Delete this option?')
    if (deleteThis) {
      request(true).delete(`/datafilters/${props.filterId}/options/${props.option.id}`).then(e => {
        if (e.status === 200) {
          props.reload();
          return alert(e.statusText)
        }
      })
    }
  }

  const {handleSubmit, register} = useForm()
  return (
    <div>
      <form className="grid grid-gap-5" onSubmit={handleSubmit(updateOption)}>
        <div className="grid grid-columns-1 grid-gap-15">
          <div className="form-row">
            <label>Label</label>
            <input ref={register({ required: false })} placeholder="Label" name="label" className="input" defaultValue={props.option.label} type="text" />
          </div>
          <div className="form-row">
            <label>Value</label>
            <input ref={register({ required: true })} placeholder="Value" name="value" className="input" defaultValue={props.option.value} type="text" />
          </div>
          {/* <div className="form-row">
            <label>Selected by default?</label>
            <input ref={register({ required: false })} name="default" className="input" defaultValue={props.option.default} type="checkbox" />
          </div> */}
        </div>
        <div className="grid grid-gap-5 grid-columns-2">
          <button className="button compact background-primary colour-white" type="submit">{props.option.id ? 'Update Option' : 'Create Option'}</button>
          {props.option.id ? <button className="button compact background-yellow colour-white" onClick={(e) => deleteOption(e)}>Delete Option</button> : null}
        </div>
      </form>
    </div>
  )
}