module.exports = Object.freeze({
    BASE: process.env.REACT_APP_BASE_URL || 'http://identeq-app.local:8000',
    DOMAIN: '/domain',
    LOGIN: '/auth/login',
    TWOFA: '/auth/token',

    AZURE_CALLBACK: '/auth/azure/callback',

    ACTIVATE: '/auth/activate',
    ACTIVATE_POST: '/auth/activate/post',

    PRODUCTS: '/products',

    AUDITS: '/audits',

    USAGE: '/usage',

    BOOKMARKS: '/bookmarks',
    SET_BOOKMARK_ORDER: 'bookmarks/order',

    CONTACT_VALIDATION: '/products/contact-validation',
    CONTACT_VALIDATION_UPLOAD: '/products/contact-validation/files',
    CONTACT_VALIDATION_INDEX: '/products/contact-validation/files',

    VOID_SALES_ALERTS_GET_CUSTOMER: '/products/void-sales-alerts/search',
    VOID_SALES_ALERTS_GET_DATES: '/products/void-sales-alerts/dates',
    VOID_SALES_ALERTS_SUMMARY: '/products/void-sales-alerts',
    VOID_SALES_ALERTS_SUMMARY_BULK: '/products/void-sales-alerts/bulk',
    VOID_SALES_ALERTS_BUY_CUSTOMER: '/products/void-sales-alerts/buy',
    VOID_SALES_ALERTS_FILE_GET: '/products/void-sales-alerts/files',
    VOID_SALES_ALERTS_FILE_UPLOAD: '/products/void-sales-alerts/files',
    VOID_SALES_ALERTS_WORKFLOW_USERS: '/products/void-sales-alerts/workflow/users',
    VOID_SALES_ALERTS_WORKFLOW: '/products/void-sales-alerts/workflow',
    VOID_SALES_ALERTS_EXCLUDE_CUSTOMER: '/products/void-sales-alerts/exclude',
    VOID_SALES_ALERTS_DATA_FILTERS: '/products/void-sales-alerts/data-filters',
    VOID_SALES_ALERTS_GROUPINGS: '/products/void-sales-alerts/groupings',

    PROPRIETOR_VALIDATION_GET_CUSTOMER: '/products/proprietor-validation/search',
    PROPRIETOR_VALIDATION_GET_DATES: '/products/proprietor-validation/dates',
    PROPRIETOR_VALIDATION_SUMMARY: '/products/proprietor-validation',
    PROPRIETOR_VALIDATION_SUMMARY_BULK: '/products/proprietor-validation/bulk',
    PROPRIETOR_VALIDATION_BUY_CUSTOMER: '/products/proprietor-validation/buy',
    PROPRIETOR_VALIDATION_FILE_GET: '/products/proprietor-validation/files',
    PROPRIETOR_VALIDATION_FILE_UPLOAD: '/products/proprietor-validation/files',
    PROPRIETOR_VALIDATION_WORKFLOW_USERS: '/products/proprietor-validation/workflow/users',
    PROPRIETOR_VALIDATION_WORKFLOW: '/products/proprietor-validation/workflow',
    PROPRIETOR_VALIDATION_EXCLUDE_CUSTOMER: '/products/proprietor-validation/exclude',
    PROPRIETOR_VALIDATION_DATA_FILTERS: '/products/proprietor-validation/data-filters',
    PROPRIETOR_VALIDATION_GROUPINGS: '/products/proprietor-validation/groupings',
    PROPRIETOR_VALIDATION_ARCGIS_PROXY: '/arcgis-proxy',

    OCCUPIED_SALE_ALERTS_GET_CUSTOMER: '/products/occupied-sale-alert/search',
    OCCUPIED_SALE_ALERTS_GET_DATES: '/products/occupied-sale-alert/dates',
    OCCUPIED_SALE_ALERTS_SUMMARY: '/products/occupied-sale-alert',
    OCCUPIED_SALE_ALERTS_SUMMARY_BULK: '/products/occupied-sale-alert/bulk',
    OCCUPIED_SALE_ALERTS_BUY_CUSTOMER: '/products/occupied-sale-alert/buy',
    OCCUPIED_SALE_ALERTS_FILE_GET: '/products/occupied-sale-alert/files',
    OCCUPIED_SALE_ALERTS_FILE_UPLOAD: '/products/occupied-sale-alert/files',
    OCCUPIED_SALE_ALERTS_WORKFLOW_USERS: '/products/occupied-sale-alert/workflow/users',
    OCCUPIED_SALE_ALERTS_WORKFLOW: '/products/occupied-sale-alert/workflow',
    OCCUPIED_SALE_ALERTS_EXCLUDE_CUSTOMER: '/products/occupied-sale-alert/exclude',
    OCCUPIED_SALE_ALERTS_DATA_FILTERS: '/products/occupied-sale-alert/data-filters',
    OCCUPIED_SALE_ALERTS_GROUPINGS: '/products/occupied-sale-alert/groupings',

    VOID_COT_ALERTS_GET_CUSTOMER: '/products/void-cot-alerts/search',
    VOID_COT_ALERTS_GET_DATES: '/products/void-cot-alerts/dates',
    VOID_COT_ALERTS_SUMMARY: '/products/void-cot-alerts',
    VOID_COT_ALERTS_SUMMARY_BULK: '/products/void-cot-alerts/bulk',
    VOID_COT_ALERTS_BUY_CUSTOMER: '/products/void-cot-alerts/buy',
    VOID_COT_ALERTS_FILE_GET: '/products/void-cot-alerts/files',
    VOID_COT_ALERTS_FILE_UPLOAD: '/products/void-cot-alerts/files',
    VOID_COT_ALERTS_WORKFLOW_USERS: '/products/void-cot-alerts/workflow/users',
    VOID_COT_ALERTS_WORKFLOW: '/products/void-cot-alerts/workflow',
    VOID_COT_ALERTS_EXCLUDE_CUSTOMER: '/products/void-cot-alerts/exclude',
    VOID_COT_ALERTS_SUMMARY_WATERFALL: '/products/void-cot-alerts/report',
    VOID_COT_ALERTS_SUMMARY_WATERFALL_DATES: '/products/void-cot-alerts/report-dates',
    VOID_COT_ALERTS_DATA_FILTERS: '/products/void-cot-alerts/data-filters',
    VOID_COT_ALERTS_GROUPINGS: '/products/void-cot-alerts/groupings',
    
    VOID_COT_ALERTS_NFI_GET_CUSTOMER: '/products/void-cot-alerts/nfi/search',
    VOID_COT_ALERTS_NFI_GET_CUSTOMER_COMBINED: '/products/void-cot-alerts/nfi/search-combined',
    VOID_COT_ALERTS_NFI_WORKFLOW_COMBINED: '/products/void-cot-alerts/nfi/search-combined-workflow',
    VOID_COT_ALERTS_NFI_EXCLUDE: '/products/void-cot-alerts/nfi/exclude',
    VOID_COT_ALERTS_NFI_SUMMARY_BULK: '/products/void-cot-alerts/nfi/bulk',
    VOID_COT_ALERTS_NFI_BUY_CUSTOMER: '/products/void-cot-alerts/nfi/buy',

    PREVIOUS_OCCUPIER_GET_CUSTOMER: '/products/previous-occupier/search',
    PREVIOUS_OCCUPIER_GET_CUSTOMER_WORKFLOW: '/products/previous-occupier/search-workflow',
    PREVIOUS_OCCUPIER_GET_DATES: '/products/previous-occupier/dates',
    PREVIOUS_OCCUPIER_SUMMARY: '/products/previous-occupier',
    PREVIOUS_OCCUPIER_SUMMARY_BULK: '/products/previous-occupier/bulk',
    PREVIOUS_OCCUPIER_BUY_CUSTOMER: '/products/previous-occupier/buy',
    PREVIOUS_OCCUPIER_FILE_GET: '/products/previous-occupier/files',
    PREVIOUS_OCCUPIER_FILE_UPLOAD: '/products/previous-occupier/files',
    PREVIOUS_OCCUPIER_WORKFLOW_USERS: '/products/previous-occupier/workflow/users',
    PREVIOUS_OCCUPIER_WORKFLOW: '/products/previous-occupier/workflow',
    PREVIOUS_OCCUPIER_EXCLUDE_CUSTOMER: '/products/previous-occupier/exclude',
    PREVIOUS_OCCUPIER_SUMMARY_WATERFALL: '/products/previous-occupier/report',
    PREVIOUS_OCCUPIER_SUMMARY_WATERFALL_DATES: '/products/previous-occupier/report-dates',
    PREVIOUS_OCCUPIER_DATA_FILTERS: '/products/previous-occupier/data-filters',
    PREVIOUS_OCCUPIER_GROUPINGS: '/products/previous-occupier/groupings',
    
    PREVIOUS_OCCUPIER_NFI_GET_CUSTOMER: '/products/previous-occupier/nfi/search',
    PREVIOUS_OCCUPIER_NFI_SUMMARY_BULK: '/products/previous-occupier/nfi/bulk',
    PREVIOUS_OCCUPIER_NFI_BUY_CUSTOMER: '/products/previous-occupier/nfi/buy',

    OCCUPIER_COT_ALERTS_GET_CUSTOMER: '/products/occupier-cot-alerts/search',
    OCCUPIER_COT_ALERTS_GET_DATES: '/products/occupier-cot-alerts/dates',
    OCCUPIER_COT_ALERTS_SUMMARY: '/products/occupier-cot-alerts',
    OCCUPIER_COT_ALERTS_SUMMARY_BULK: '/products/occupier-cot-alerts/bulk',
    OCCUPIER_COT_ALERTS_BUY_CUSTOMER: '/products/occupier-cot-alerts/buy',
    OCCUPIER_COT_ALERTS_FILE_GET: '/products/occupier-cot-alerts/files',
    OCCUPIER_COT_ALERTS_FILE_UPLOAD: '/products/occupier-cot-alerts/files',
    OCCUPIER_COT_ALERTS_WORKFLOW_USERS: '/products/occupier-cot-alerts/workflow/users',
    OCCUPIER_COT_ALERTS_WORKFLOW: '/products/occupier-cot-alerts/workflow',
    OCCUPIER_COT_ALERTS_EXCLUDE_CUSTOMER: '/products/occupier-cot-alerts/exclude',
    OCCUPIER_COT_ALERTS_SUMMARY_WATERFALL: '/products/occupier-cot-alerts/report',
    OCCUPIER_COT_ALERTS_SUMMARY_WATERFALL_DATES: '/products/occupier-cot-alerts/report-dates',
    OCCUPIER_COT_ALERTS_DATA_FILTERS: '/products/occupier-cot-alerts/data-filters',
    OCCUPIER_COT_ALERTS_GROUPINGS: '/products/occupier-cot-alerts/groupings',

    OCCUPIED_COT_ALERTS_GET_CUSTOMER: '/products/occupied-cot-alerts/search',
    OCCUPIED_COT_ALERTS_GET_DATES: '/products/occupied-cot-alerts/dates',
    OCCUPIED_COT_ALERTS_SUMMARY: '/products/occupied-cot-alerts',
    OCCUPIED_COT_ALERTS_SUMMARY_BULK: '/products/occupied-cot-alerts/bulk',
    OCCUPIED_COT_ALERTS_BUY_CUSTOMER: '/products/occupied-cot-alerts/buy',
    OCCUPIED_COT_ALERTS_FILE_GET: '/products/occupied-cot-alerts/files',
    OCCUPIED_COT_ALERTS_FILE_UPLOAD: '/products/occupied-cot-alerts/files',
    OCCUPIED_COT_ALERTS_WORKFLOW_USERS: '/products/occupied-cot-alerts/workflow/users',
    OCCUPIED_COT_ALERTS_WORKFLOW: '/products/occupied-cot-alerts/workflow',
    OCCUPIED_COT_ALERTS_EXCLUDE_CUSTOMER: '/products/occupied-cot-alerts/exclude',
    OCCUPIED_COT_ALERTS_DATA_FILTERS: '/products/occupied-cot-alerts/data-filters',
    OCCUPIED_COT_ALERTS_GROUPINGS: '/products/occupied-cot-alerts/groupings',

    AFFORDABILITY_ALERTS_GET_CUSTOMER: '/products/affordability-alerts/search',
    AFFORDABILITY_ALERTS_GET_DATES: '/products/affordability-alerts/dates',
    AFFORDABILITY_ALERTS_SUMMARY: '/products/affordability-alerts',
    AFFORDABILITY_ALERTS_SUMMARY_BULK: '/products/affordability-alerts/bulk',
    AFFORDABILITY_ALERTS_BUY_CUSTOMER: '/products/affordability-alerts/buy',
    AFFORDABILITY_ALERTS_FILE_GET: '/products/affordability-alerts/files',
    AFFORDABILITY_ALERTS_FILE_UPLOAD: '/products/affordability-alerts/files',
    AFFORDABILITY_ALERTS_WORKFLOW_USERS: '/products/affordability-alerts/workflow/users',
    AFFORDABILITY_ALERTS_WORKFLOW: '/products/affordability-alerts/workflow',
    AFFORDABILITY_ALERTS_EXCLUDE_CUSTOMER: '/products/affordability-alerts/exclude',
    AFFORDABILITY_ALERTS_DATA_FILTERS: '/products/affordability-alerts/data-filters',
    AFFORDABILITY_ALERTS_GROUPINGS: '/products/affordability-alerts/groupings',

    VACANT_COT_ALERTS_GET_CUSTOMER: '/products/vacant-cot-alerts/search',
    VACANT_COT_ALERTS_GET_DATES: '/products/vacant-cot-alerts/dates',
    VACANT_COT_ALERTS_SUMMARY: '/products/vacant-cot-alerts',
    VACANT_COT_ALERTS_SUMMARY_BULK: '/products/vacant-cot-alerts/bulk',
    VACANT_COT_ALERTS_BUY_CUSTOMER: '/products/vacant-cot-alerts/buy',
    VACANT_COT_ALERTS_FILE_GET: '/products/vacant-cot-alerts/files',
    VACANT_COT_ALERTS_FILE_UPLOAD: '/products/vacant-cot-alerts/files',
    VACANT_COT_ALERTS_WORKFLOW_USERS: '/products/vacant-cot-alerts/workflow/users',
    VACANT_COT_ALERTS_WORKFLOW: '/products/vacant-cot-alerts/workflow',
    VACANT_COT_ALERTS_EXCLUDE_CUSTOMER: '/products/vacant-cot-alerts/exclude',
    VACANT_COT_ALERTS_DATA_FILTERS: '/products/vacant-cot-alerts/data-filters',
    VACANT_COT_ALERTS_GROUPINGS: '/products/vacant-cot-alerts/groupings',
    VACANT_COT_ALERTS_UPDATE_STATUS: '/products/vacant-cot-alerts/updateStatus',
    VACANT_COT_ALERTS_CONFIRMATION: '/products/vacant-cot-alerts/confirm',
    VACANT_COT_ALERTS_ABP_BY_POSTCODE: '/products/vacant-cot-alerts/abp/postcode',

    VACANT_COT_ALERTS_UNALLOCATE_GET: '/products/vacant-cot-alerts/unallocate',
    VACANT_COT_ALERTS_UNALLOCATE_POST: '/products/vacant-cot-alerts/unallocate',

    VACANT_COT_ALERTS_DASHBOARD_MONTH_AND_YEAR: '/products/vacant-cot-alerts/dashboard/monthAndYear',
    VACANT_COT_ALERTS_DASHBOARD_MOVEINS_PERFORMED: '/products/vacant-cot-alerts/dashboard/moveinsPerformed',
    VACANT_COT_ALERTS_DASHBOARD_MOVEINS_TEAM_PERFORMED: '/products/vacant-cot-alerts/dashboard/teamPerformance',

    VACANT_COT_ALERTS_DASHBOARD_WORKFLOW: '/products/vacant-cot-alerts/dashboard/workflow',
    VACANT_COT_ALERTS_DASHBOARD_PERFORMANCE: '/products/vacant-cot-alerts/dashboard/performance',
    VACANT_COT_ALERTS_DASHBOARD_CONFIDENCE: '/products/vacant-cot-alerts/dashboard/confidence',
    VACANT_COT_ALERTS_DASHBOARD_REFRESH: '/products/vacant-cot-alerts/dashboard/refresh',

    VACANT_COT_ALERTS_DASHBOARD_WHOLESALER: '/products/vacant-cot-alerts/dashboard/wholesalers',

    OCCUPIER_TELEPHONE_VALIDATION_GET_CUSTOMER: '/products/occupier-telephone-validation/search',
    OCCUPIER_TELEPHONE_VALIDATION_GET_DATES: '/products/occupier-telephone-validation/dates',
    OCCUPIER_TELEPHONE_VALIDATION_SUMMARY: '/products/occupier-telephone-validation',
    OCCUPIER_TELEPHONE_VALIDATION_SUMMARY_BULK: '/products/occupier-telephone-validation/bulk',
    OCCUPIER_TELEPHONE_VALIDATION_BUY_CUSTOMER: '/products/occupier-telephone-validation/buy',
    OCCUPIER_TELEPHONE_VALIDATION_FILE_GET: '/products/occupier-telephone-validation/files',
    OCCUPIER_TELEPHONE_VALIDATION_FILE_UPLOAD: '/products/occupier-telephone-validation/files',
    OCCUPIER_TELEPHONE_VALIDATION_WORKFLOW_USERS: '/products/occupier-telephone-validation/workflow/users',
    OCCUPIER_TELEPHONE_VALIDATION_WORKFLOW: '/products/occupier-telephone-validation/workflow',
    OCCUPIER_TELEPHONE_VALIDATION_EXCLUDE_CUSTOMER: '/products/occupier-telephone-validation/exclude',
    OCCUPIER_TELEPHONE_VALIDATION_DATA_FILTERS: '/products/occupier-telephone-validation/data-filters',
    OCCUPIER_TELEPHONE_VALIDATION_GROUPINGS: '/products/occupier-telephone-validation/groupings',
    OCCUPIER_TELEPHONE_VALIDATION_UPDATE_STATUS: '/products/occupier-telephone-validation/updateStatus',
    OCCUPIER_TELEPHONE_VALIDATION_SUBMIT_ANSWERS: '/products/occupier-telephone-validation/submitSurveyAnswers',
    OCCUPIER_TELEPHONE_VALIDATION_UPDATE_CUSTOMER_PROPERTY: '/products/occupier-telephone-validation/update-customer-property',

    OCCUPIER_TELEPHONE_VALIDATION_UNALLOCATE_GET: '/products/occupier-telephone-validation/unallocate',
    OCCUPIER_TELEPHONE_VALIDATION_UNALLOCATE_POST: '/products/occupier-telephone-validation/unallocate',

    OCCUPIER_TELEPHONE_VALIDATION_DASHBOARD_MONTH_AND_YEAR: '/products/occupier-telephone-validation/dashboard/monthAndYear',
    OCCUPIER_TELEPHONE_VALIDATION_DASHBOARD_MOVEINS_PERFORMED: '/products/occupier-telephone-validation/dashboard/moveinsPerformed',
    OCCUPIER_TELEPHONE_VALIDATION_DASHBOARD_MOVEINS_TEAM_PERFORMED: '/products/occupier-telephone-validation/dashboard/teamPerformance',

    OCCUPIER_TELEPHONE_VALIDATION_DASHBOARD_WORKFLOW: '/products/occupier-telephone-validation/dashboard/workflow',
    OCCUPIER_TELEPHONE_VALIDATION_DASHBOARD_PERFORMANCE: '/products/occupier-telephone-validation/dashboard/performance',
    OCCUPIER_TELEPHONE_VALIDATION_DASHBOARD_CONFIDENCE: '/products/occupier-telephone-validation/dashboard/confidence',
    OCCUPIER_TELEPHONE_VALIDATION_DASHBOARD_REFRESH: '/products/occupier-telephone-validation/dashboard/refresh',

    OCCUPIER_TELEPHONE_VALIDATION_DASHBOARD_WHOLESALER: '/products/occupier-telephone-validation/dashboard/wholesalers',
    OCCUPIER_TELEPHONE_VALIDATION_MDS_SPID: '/products/occupier-telephone-validation/mds-spid',

    TWILIO_TOKEN: '/twilio/token',
    TWILIO_GET_RECORDINGS: '/twilio/getRecordings',
    TWILIO_DOWNLOAD_RECORDING: '/twilio/download',
    TWILIO_RTV_LOOKUP: '/twilio/getAddressesFromPostcode',

    COMPANIES_HOUSE_ALERTS_GET_CUSTOMER: '/products/companies-house-alerts/search',
    COMPANIES_HOUSE_ALERTS_GET_DATES: '/products/companies-house-alerts/dates',
    COMPANIES_HOUSE_ALERTS_SUMMARY: '/products/companies-house-alerts',
    COMPANIES_HOUSE_ALERTS_STATS: '/products/companies-house-alerts/stats',
    COMPANIES_HOUSE_ALERTS_STATS_QUALITY: '/products/companies-house-alerts/stats/quality',
    COMPANIES_HOUSE_ALERTS_STATS_FILING: '/products/companies-house-alerts/stats/filing',
    COMPANIES_HOUSE_ALERTS_STATS_COMPANY: '/products/companies-house-alerts/stats/company',
    COMPANIES_HOUSE_ALERTS_SUMMARY_BULK: '/products/companies-house-alerts/bulk',
    COMPANIES_HOUSE_ALERTS_BUY_CUSTOMER: '/products/companies-house-alerts/buy',
    COMPANIES_HOUSE_ALERTS_FILE_GET: '/products/companies-house-alerts/files',
    COMPANIES_HOUSE_ALERTS_FILE_UPLOAD: '/products/companies-house-alerts/files',
    COMPANIES_HOUSE_ALERTS_WORKFLOW_USERS: '/products/companies-house-alerts/workflow/users',
    COMPANIES_HOUSE_ALERTS_WORKFLOW: '/products/companies-house-alerts/workflow',
    COMPANIES_HOUSE_ALERTS_EXCLUDE_CUSTOMER: '/products/companies-house-alerts/exclude',
    COMPANIES_HOUSE_ALERTS_DATA_FILTERS: '/products/companies-house-alerts/data-filters',
    COMPANIES_HOUSE_ALERTS_GROUPINGS: '/products/companies-house-alerts/groupings',
    COMPANIES_HOUSE_ALERTS_WHOLESALER: '/products/companies-house-alerts/wholesaler',

    COT_VALIDATION_GET_CUSTOMER: '/products/cot-validation/search',
    COT_VALIDATION_GET_DATES: '/products/cot-validation/dates',
    COT_VALIDATION_SUMMARY: '/products/cot-validation',
    COT_VALIDATION_STATS: '/products/cot-validation/stats',
    COT_VALIDATION_STATS_QUALITY: '/products/cot-validation/stats/quality',
    COT_VALIDATION_STATS_FILING: '/products/cot-validation/stats/filing',
    COT_VALIDATION_SUMMARY_BULK: '/products/cot-validation/bulk',
    COT_VALIDATION_BUY_CUSTOMER: '/products/cot-validation/buy',
    COT_VALIDATION_FILE_GET: '/products/cot-validation/files',
    COT_VALIDATION_FILE_UPLOAD: '/products/cot-validation/files',
    COT_VALIDATION_WORKFLOW_USERS: '/products/cot-validation/workflow/users',
    COT_VALIDATION_WORKFLOW: '/products/cot-validation/workflow',
    COT_VALIDATION_EXCLUDE_CUSTOMER: '/products/cot-validation/exclude',
    COT_VALIDATION_DATA_FILTERS: '/products/cot-validation/data-filters',
    COT_VALIDATION_GROUPINGS: '/products/cot-validation/groupings',
    COT_VALIDATION_GET_DATA: '/products/cot-validation/data',
    COT_VALIDATION_SUMMARY_WATERFALL: '/products/cot-validation/report',
    COT_VALIDATION_SUMMARY_WATERFALL_DATES: '/products/cot-validation/report-dates',

    COT_VALIDATION_GET_MANUAL_REVIEW: '/products/cot-validation/manual-review',
    COT_VALIDATION_GET_WORKFLOW_MANUAL_REVIEW: '/products/cot-validation/manual-review/workflow',
    COT_VALIDATION_MANUAL_REVIEW_BULK: '/products/cot-validation/manual-review/bulk',
    COT_VALIDATION_UPDATE_MANUAL_REVIEW: '/products/cot-validation/manual-review',

    TARIFF_VALIDATION_GET_CUSTOMER: '/products/tariff-validation/search',
    TARIFF_VALIDATION_GET_DATES: '/products/tariff-validation/dates',
    TARIFF_VALIDATION_SUMMARY: '/products/tariff-validation',
    TARIFF_VALIDATION_SUMMARY_BULK: '/products/tariff-validation/bulk',
    TARIFF_VALIDATION_BUY_CUSTOMER: '/products/tariff-validation/buy',
    TARIFF_VALIDATION_FILE_GET: '/products/tariff-validation/files',
    TARIFF_VALIDATION_FILE_UPLOAD: '/products/tariff-validation/files',
    TARIFF_VALIDATION_WORKFLOW_USERS: '/products/tariff-validation/workflow/users',
    TARIFF_VALIDATION_WORKFLOW: '/products/tariff-validation/workflow',
    TARIFF_VALIDATION_EXCLUDE_CUSTOMER: '/products/tariff-validation/exclude',
    TARIFF_VALIDATION_SUMMARY_WATERFALL: '/products/tariff-validation/report',
    TARIFF_VALIDATION_SUMMARY_WATERFALL_DATES: '/products/tariff-validation/report-dates',
    TARIFF_VALIDATION_DATA_FILTERS: '/products/tariff-validation/data-filters',
    TARIFF_VALIDATION_GROUPINGS: '/products/tariff-validation/groupings',

    ADDRESS_ALERTS_GET_KPIS: '/products/address-alerts/kpi',
    ADDRESS_ALERTS_DATA_FILTERS: '/products/address-alerts/data-filters',
    ADDRESS_ALERTS_DATA_SUMMARY: '/products/address-alerts/summary',
    ADDRESS_ALERTS_FILE_GET: '/products/address-alerts/files',
    ADDRESS_ALERTS_FILE_UPLOAD: '/products/address-alerts/files',
    ADDRESS_ALERTS_GROUPINGS: '/products/address-alerts/groupings',

    BUSINESS_TELE_APPEND_DATES: '/products/business-tele-append/file-dates',
    BUSINESS_TELE_APPEND_DATA: '/products/business-tele-append/data',
    BUSINESS_TELE_APPEND_GRAPH: '/products/business-tele-append/graph',
    BUSINESS_TELE_APPEND_TABLE: '/products/business-tele-append/table',
    BUSINESS_TELE_FILE_DOWNLOAD: '/products/business-tele-append/files',
    BUSINESS_TELE_FILE_UPLOAD: '/products/business-tele-append/files',

    HOME_MOVER_ALERTS_GROUPINGS: '/products/home-mover-alerts/groupings',
    HOME_MOVER_ALERTS_FILTERS: '/products/home-mover-alerts/filters',
    HOME_MOVER_ALERTS_DATA: '/products/home-mover-alerts/data',
    HOME_MOVER_ALERTS_DATES: '/products/home-mover-alerts/dates',
    HOME_MOVER_ALERTS_FILES: '/products/home-mover-alerts/files',

    GAP_SITES_ALERTS_OUTWARDS: '/products/gap-sites-alerts/outwards',
    GAP_SITES_ALERTS_POSTCODES: '/products/gap-sites-alerts/postcodes',
    GAP_SITES_ALERTS_MAP_DATA: '/products/gap-sites-alerts/map-data',
    GAP_SITES_ALERTS_DATA: '/products/gap-sites-alerts/data',
    GAP_SITES_ALERTS_TOTALS: '/products/gap-sites-alerts/totals',
    GAP_SITES_ALERTS_UPDATE_STATUS: '/products/gap-sites-alerts/updateStatus',
    GAP_SITES_ALERTS_UPDATE_CUSTOMER: 'products/gap-sites-alerts/updateCustomer',
    GAP_SITES_ALERTS_SUMMARY: '/products/gap-sites-alerts/summary',
    GAP_SITES_ALERTS_GROUPINGS: '/products/gap-sites-alerts/groupings',
    GAP_SITES_ALERTS_FILTERS: '/products/gap-sites-alerts/filters',

    GAP_SITES_ALERTS_WORKFLOW: '/products/gap-sites-alerts/workflow',
    GAP_SITES_ALERTS_WORKFLOW_USERS: '/products/gap-sites-alerts/workflow/users',
    GAP_SITES_ALERTS_BULK: '/products/gap-sites-alerts/bulk',
    GAP_SITES_ALERTS_GET_CUSTOMER: '/products/gap-sites-alerts/search',
    GAP_SITES_ALERTS_BUY_CUSTOMER: '/products/gap-sites-alerts/buy',
    GAP_SITES_ALERTS_EXCLUDE_CUSTOMER: '/products/gap-sites-alerts/exclude',
    GAP_SITES_ALERTS_DOWNLOAD: '/products/gap-sites-alerts/files',
    GAP_SITES_ALERTS_UPLOAD: '/products/gap-sites-alerts/files',

    REAL_TIME_VALIDATION_SEARCH: '/products/real-time-validation/search',
    REAL_TIME_VALIDATION_LOOKUP: '/products/real-time-validation/lookup',

    // Summary
    BUSINESS_ADDRESS_ALERTS_SEARCH_BY_SPID: '/products/business-address-alerts/search',
    BUSINESS_ADDRESS_ALERTS_GET_ADDRESSES: '/products/business-address-alerts/addresses',
    BUSINESS_ADDRESS_ALERTS_GET_VOAS: '/products/business-address-alerts/voas',
    BUSINESS_ADDRESS_ALERTS_GET_SAA: '/products/business-address-alerts/saa',
    // Generics
    BUSINESS_ADDRESS_ALERTS_GROUPINGS: '/products/business-address-alerts/groupings',
    BUSINESS_ADDRESS_ALERTS_FILTERS: '/products/business-address-alerts/filters',
    BUSINESS_ADDRESS_ALERTS_DATA: '/products/business-address-alerts/data',
    BUSINESS_ADDRESS_ALERTS_DATES: '/products/business-address-alerts/dates',
    BUSINESS_ADDRESS_ALERTS_FILES: '/products/business-address-alerts/files',
    // Workflow
    BUSINESS_ADDRESS_ALERTS_PURCHASE: '/products/business-address-alerts/buy',
    BUSINESS_ADDRESS_ALERTS_EXCLUDE: '/products/business-address-alerts/exclude',
    BUSINESS_ADDRESS_ALERTS_WF_USERS: '/products/business-address-alerts/workflow/users',
    BUSINESS_ADDRESS_ALERTS_WF_ITEMS: '/products/business-address-alerts/workflow',
    // Bulk
    BUSINESS_ADDRESS_ALERTS_BULK_BASE: '/products/business-address-alerts/bulk',
    //
    BUSINESS_ADDRESS_ALERTS_SELECT_MATCH: '/products/business-address-alerts/select-match',

    // Summary
    WHOLESALER_GAPS_SEARCH_BY_SPID: '/products/wholesaler-gaps/search',
    WHOLESALER_GAPS_SEARCH_BY_POSTCODE: '/products/wholesaler-gaps/search-postcode',
    WHOLESALER_GAPS_GET_ADDRESSES: '/products/wholesaler-gaps/addresses',
    WHOLESALER_GAPS_GET_CMOS_ADDRESSES: '/products/wholesaler-gaps/cmos-address',
    WHOLESALER_GAPS_GET_CMOS_ADDRESSES_VOA: '/products/wholesaler-gaps/cmos-address-voa',
    WHOLESALER_GAPS_GET_IDENTEQ_ADDRESSES: '/products/wholesaler-gaps/identeq',
    WHOLESALER_GAPS_GET_IDENTEQ_ADDRESSES_VOA: '/products/wholesaler-gaps/identeq-voa',
    WHOLESALER_GAPS_GET_VOAS: '/products/wholesaler-gaps/voas',
    WHOLESALER_GAPS_GET_SAA: '/products/wholesaler-gaps/saa',
    WHOLESALER_GAPS_GET_GAP: '/products/wholesaler-gaps/gap',
    // Generics
    WHOLESALER_GAPS_GROUPINGS: '/products/wholesaler-gaps/groupings',
    WHOLESALER_GAPS_FILTERS: '/products/wholesaler-gaps/filters',
    WHOLESALER_GAPS_DATA: '/products/wholesaler-gaps/data',
    WHOLESALER_GAPS_DATES: '/products/wholesaler-gaps/dates',
    WHOLESALER_GAPS_FILES: '/products/wholesaler-gaps/files',
    // Workflow
    WHOLESALER_GAPS_PURCHASE: '/products/wholesaler-gaps/buy',
    WHOLESALER_GAPS_EXCLUDE: '/products/wholesaler-gaps/exclude',
    WHOLESALER_GAPS_WF_USERS: '/products/wholesaler-gaps/workflow/users',
    WHOLESALER_GAPS_WF_ITEMS: '/products/wholesaler-gaps/workflow',
    // Bulk
    WHOLESALER_GAPS_BULK_BASE: '/products/wholesaler-gaps/bulk',
    //
    WHOLESALER_GAPS_SELECT_MATCH: '/products/wholesaler-gaps/select-match',
    WHOLESALER_GAPS_CLEAR_MATCH: '/products/wholesaler-gaps/clear-match',
    WHOLESALER_GAPS_SELECT_MATCH_VOA: '/products/wholesaler-gaps/select-match-voa',
    WHOLESALER_GAPS_SUBMIT_GAP: '/products/wholesaler-gaps/submit-gap',

    //
    WHOLESALER_GAPS_TRIGGER_BATCH: '/products/wholesaler-gaps/trigger-batch',

    //
    //
    //
    
    // Generics
    IDENTEQ_OCCUPIER_VALIDATION_GROUPINGS: '/products/identeq-occupier-validation/groupings',
    IDENTEQ_OCCUPIER_VALIDATION_FILTERS: '/products/identeq-occupier-validation/filters',
    IDENTEQ_OCCUPIER_VALIDATION_DATA: '/products/identeq-occupier-validation/data',
    IDENTEQ_OCCUPIER_VALIDATION_DATES: '/products/identeq-occupier-validation/dates',
    IDENTEQ_OCCUPIER_VALIDATION_FILES: '/products/identeq-occupier-validation/files',
    IDENTEQ_OCCUPIER_VALIDATION_UPDATE_STATUS: '/products/identeq-occupier-validation/updateStatus',
    IDENTEQ_OCCUPIER_VALIDATION_SUBMIT_ANSWERS: '/products/identeq-occupier-validation/submitSurveyAnswers',
    // Workflow
    IDENTEQ_OCCUPIER_VALIDATION_PURCHASE: '/products/identeq-occupier-validation/buy',
    IDENTEQ_OCCUPIER_VALIDATION_EXCLUDE: '/products/identeq-occupier-validation/exclude',
    IDENTEQ_OCCUPIER_VALIDATION_WF_USERS: '/products/identeq-occupier-validation/workflow/users',
    IDENTEQ_OCCUPIER_VALIDATION_WF_ITEMS: '/products/identeq-occupier-validation/workflow',
    // Bulk
    IDENTEQ_OCCUPIER_VALIDATION_BULK_BASE: '/products/identeq-occupier-validation/bulk',
    //
    IDENTEQ_OCCUPIED_VALIDATION_LOOKUP: '/products/identeq-occupier-validation/lookup',

    // Generics
    OCCUPIER_VALIDATION_GROUPINGS: '/products/occupier-validation/groupings',
    OCCUPIER_VALIDATION_FILTERS: '/products/occupier-validation/filters',
    OCCUPIER_VALIDATION_DATA: '/products/occupier-validation/data',
    OCCUPIER_VALIDATION_DATES: '/products/occupier-validation/dates',
    OCCUPIER_VALIDATION_FILES: '/products/occupier-validation/files',
    OCCUPIER_VALIDATION_UPDATE_STATUS: '/products/occupier-validation/updateStatus',
    OCCUPIER_VALIDATION_UNALLOCATE_GET: '/products/occupier-validation/unallocate',
    OCCUPIER_VALIDATION_UNALLOCATE_POST: '/products/occupier-validation/unallocate',
    // Workflow
    OCCUPIER_VALIDATION_PURCHASE: '/products/occupier-validation/buy',
    OCCUPIER_VALIDATION_EXCLUDE: '/products/occupier-validation/exclude',
    OCCUPIER_VALIDATION_WF_USERS: '/products/occupier-validation/workflow/users',
    OCCUPIER_VALIDATION_WF_ITEMS: '/products/occupier-validation/workflow',
    // Bulk
    OCCUPIER_VALIDATION_BULK_BASE: '/products/occupier-validation/bulk',
    //
    OCCUPIED_VALIDATION_LOOKUP: '/products/occupier-validation/lookup',
    //
    OCCUPIER_VALIDATION_DASHBOARD_MONTH_AND_YEAR: '/products/occupier-validation/dashboard/monthAndYear',
    OCCUPIER_VALIDATION_DASHBOARD_MOVEINS_PERFORMED: '/products/occupier-validation/dashboard/moveinsPerformed',
    OCCUPIER_VALIDATION_DASHBOARD_MOVEINS_TEAM_PERFORMED: '/products/occupier-validation/dashboard/teamPerformance',

    OCCUPIER_VALIDATION_DASHBOARD_WORKFLOW: '/products/occupier-validation/dashboard/workflow',
    OCCUPIER_VALIDATION_DASHBOARD_PERFORMANCE: '/products/occupier-validation/dashboard/performance',
    OCCUPIER_VALIDATION_DASHBOARD_CONFIDENCE: '/products/occupier-validation/dashboard/confidence',
    OCCUPIER_VALIDATION_DASHBOARD_REFRESH: '/products/occupier-validation/dashboard/refresh',

    OCCUPIER_VALIDATION_DASHBOARD_WHOLESALER: '/products/occupier-validation/dashboard/wholesalers',

    COMPANY_ADMIN_USERS: '/company-admin/users',

    ADMIN_COMPANIES_INDEX: '/companies',
    ADMIN_COMPANIES_COMPANY: '/companies',

    ADMIN_EXTRA_PERMISSIONS: '/extra-permissions',

    ADMIN_COMPANIES_COMPANY_FRONTEND: '/frontends',

    ADMIN_COMPANIES_USERS: '/users',
    ADMIN_COMPANIES_USER: '/users',

    ADMIN_USERS_INDEX: '/users',

    ADMIN_BRANDING_INDEX: '/branding',
    ADMIN_BRANDING_POST: '/branding',

    ADMIN_PRODUCTS_INDEX: '/products',
    ADMIN_PRODUCTS_UPDATE_TUTORIALS: '/tutorials/update-tutorials',
    ADMIN_REAL_PRODUCTS_INDEX: '/real-products',

    ADMIN_VERSION_HISTORY_CHANGES: '/version-history/changes',
    ADMIN_VERSION_HISTORY_VERSION_NUMBERS: '/version-history/versions',
    ADMIN_VERSION_HISTORY_INSERT: '/version-history/insert-changes',
    ADMIN_VERSION_HISTORY_EDIT: '/version-history/edit',
    ADMIN_VERSION_HISTORY_DELETE: '/version-history/delete',
    ADMIN_VERSION_HISTORY_SEEN_CHANGES: '/version-history/seen-changes',


    RESET_REQUEST: '/auth/reset/request',
    RESET_CHECK: '/auth/reset/check',
    RESET_RESET: '/auth/reset',
});
