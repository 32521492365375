import React from 'react';
import Modal from 'react-modal'
import Dropzone from '../../components/dropzone/dropzone'
import Filesaver from 'file-saver'
import request from '../../helpers/request'
import { useEffect } from 'react';
import Axios from 'axios';
import session from '../../stores/session';

const timeSince = (date) => {
    var seconds = Math.floor((new Date() - date) / 1000)

    var interval = Math.floor(seconds / 31536000)

    if (interval > 1) {
        return interval + ' years'
    }
    interval = Math.floor(seconds / 2592000)
    if (interval > 1) {
        return interval + ' months'
    }
    interval = Math.floor(seconds / 86400)
    if (interval > 1) {
        return interval + ' days'
    }
    interval = Math.floor(seconds / 3600)
    if (interval > 1) {
        return interval + ' hours'
    }
    interval = Math.floor(seconds / 60)
    if (interval > 1) {
        return interval + ' minutes'
    }
    return Math.floor(seconds) + ' seconds'
}

export default function S3Modal ({ showModal, closeModal, modalMode, listingUrl, uploadUrl, ...props })
{
    const [files, setFiles] = React.useState([]);
    const [filesForUpload, setFilesForUpload] = React.useState([]);
    const [modalMessage, setModalMessage] = React.useState('');
    const [uploadMessages, setUploadMessages] = React.useState([]);

    const downloadFile = (url, name) => {
        Filesaver.saveAs(url, name)
    }

    const truncateString = (str, num) => {
        if (str.length <= num) {
            return str
        }

        return str.slice(0, num) + '...'
    }

    const onUpload = (acceptedFiles, rejectedFiles) => {
        setModalMessage(``);
        if (rejectedFiles.length > 0) {
            rejectedFiles.forEach((rejected) => {
                updateFileMessages(rejected.file.name, `Error uploading ${truncateString(rejected.file.name, 12)} - ${rejected.errors[0].message}`)
            })
            return
        }
        if (acceptedFiles.length > 2 || filesForUpload.length >= 2) {
            setModalMessage(`Too many files selected - please limit uploads to 2 files`);
            return
        }
        if (acceptedFiles.length > 0) {
            setFilesForUpload(prevFiles => [...prevFiles, ...acceptedFiles]);
        }
    }

    const submit = () => {
        const fileNames = [];
        filesForUpload.forEach((file) => {
            fileNames.push(file.name);
            updateFileMessages(file.name, `Uploading ${truncateString(file.name, 12)}... Please Wait...`)
        });
        
        request(true).post(uploadUrl, {
            fileNames: fileNames
        }).then(r => {
            r.data.urls.forEach((url, index) => {
                Axios.put(url, filesForUpload[index], {
                    headers: {
                        'Content-Type': filesForUpload[index].type
                    }
                }).then(r => {
                    updateFileMessages(filesForUpload[index].name, `Successfully uploaded ${truncateString(filesForUpload[index].name, 12)}...` );
                }).catch(e => {
                    updateFileMessages(filesForUpload[index].name, `'An error occurred whilst uploading ${truncateString(filesForUpload[index].name, 12)} this file to S3.` );
                })
            })
        }).catch(e => {
            console.log(e)
            setUploadMessages([])
            setModalMessage('An error occurred whilst uploading!');
        });
    }

    const removeFile = (indexToRemove) => {
        setUploadMessages(prevMessages => prevMessages.filter(_ => (_.file !== filesForUpload[indexToRemove].name)));
        setFilesForUpload(prevFiles => prevFiles.filter((_, index) => index !== indexToRemove));
    }  

    const clearFiles = () => {
        setModalMessage(``);
        setUploadMessages([])
        setFilesForUpload([]);
    }

    const getFile = (url, name) => {
        request(true).get(url).then(r => {
            downloadFile(r.data.download, name);
        }).catch(e => {
            console.log(e);
        });
    }

    const updateFileMessages = (fileName, message) => {
        setUploadMessages(prevState => {
            const existingIndex = prevState.findIndex(item => item.file === fileName);
            return existingIndex !== -1 ? 
                prevState.map((item, index) => (index === existingIndex ? { ...item, message: message} : item)) :
                [...prevState, { file: fileName, message: message}];
        });
    }

    useEffect(() => {
        if (modalMode === 'download') {
          request(true)
            .get(listingUrl)
            .then((r) => {
                setFiles(r.data.files);
                setModalMessage(" ");
            })
            .catch((r) => {
                setFiles([]);
                setModalMessage('An error occurred whilst retrieving the file listing!');
            })
        }
    }, [modalMode, listingUrl]);

    return (
        <Modal
            style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
            className="card"
            isOpen={showModal}
            onRequestClose={closeModal}
            contentLabel={modalMode === 'download' ? 'Download Return File' : 'Issue File'}
            appElement={document.getElementById('root')}
        >
            <h2>
                {modalMode === 'download' ? 'Download Return File' : 'Issue File'}
            </h2>
            {modalMode === 'download' ? (
                <div className="download">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Last Modified</th>
                                <th>Size (KB)</th>
                            </tr>
                        </thead>
                        <tbody>
                            {files.map((file) => {
                                return (
                                    <tr key={file.name}>
                                        <td>
                                            <p style={{ color: 'blue', cursor: 'pointer' }} onClick={() => getFile(file.download, file.name)}>
                                                {truncateString(file.name, 25)}
                                            </p>
                                        </td>
                                        <td>{ timeSince(new Date(file.last_modified * 1000)) } ago</td>
                                        <td>{(file.size / 1000).toFixed(1).toLocaleString()}</td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                    { modalMessage ?
                        <p className="modal-message">{modalMessage}</p>
                    :
                        <i style={{ marginTop: '10px' }} className='fas fa-loader fa-fw fa-spin fa-2x'></i>
                    }
                </div>
            ) : (
                    <div className="upload">
                        <Dropzone onDrop={onUpload} />
                        { uploadMessages.map((message, index) => {
                            return (
                                <p className="modal-message">{message.message}</p>
                            )
                        })}
                        { modalMessage ?
                            <p className="modal-message">{modalMessage}</p>
                        : 
                            ''
                        }
                        { filesForUpload.length ?
                            <>
                                <table className='table borders squish' style={{textAlign: 'center', marginBottom: '15px'}} >
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Last Modified</th>
                                            <th>Size (KB)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        { 
                                            filesForUpload.map((file, index) => {
                                                return(   
                                                    <>
                                                        <tr>
                                                            <td>{file.name}</td>
                                                            <td>{new Date(file.lastModified).toLocaleString()}</td>
                                                            <td>{file.size}</td>
                                                            <i class="fa-regular fa-circle-xmark file-remove" onClick={() => removeFile(index)}></i>
                                                        </tr>
                                                    </>
                                                )
                                            })
                                        }
                                    </tbody>
                                </table>
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <button className="button compact colour-white" style={{backgroundColor: 'var(--red)'}} onClick={clearFiles}>Clear Files</button>
                                    <button className="button compact background-primary colour-white" onClick={submit}>Submit</button>
                                </div>
                            </>
                        : null}
                    </div>
                )}
        </Modal>
    )
}
