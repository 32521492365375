import React from 'react'

export default function DataGroupings({groupings = [], group, setGroup}) {
    return (
        <div style={{ display: 'flex', gap: 10, alignItems: 'center', height: '100%' }}>
            {
                groupings.map(_group => {
                    return (
                        <button key={_group.id} style={{color: group === _group.target_column ? 'black' : 'white', backgroundColor: 'unset', outline: 'unset', border: 'unset', fontWeight: 'bold'}} onClick={ () => setGroup(_group.target_column) }>
                            {_group.title}
                        </button>
                    )
                })
            }
        </div>
    )
}
