import React from 'react'
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react'
import Select from 'react-select'
import request from '../../helpers/request';

export default function ProductSelect({ onChange }) {
  const [products, setProducts] = useState([]);
  const productOptions = useMemo(() => {
    return products.map((product) => {
      return {
        label: product.product_key,
        value: product.id
      }
    })
  }, [products]);

  useEffect(() => {
    request(true).get('/real-products').then(data => {
      if (data.status === 200) {
        setProducts(data.data)
      }
    })
  }, [])

  return (
    <Select placeholder="Real Product" isClearable onChange={onChange} options={productOptions} isLoading={products.length === 0} />
  )
}
