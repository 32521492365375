import React, { Component } from 'react'
import Navigation from '../../components/navigation/navigation'
import Footer from '../../components/footer/footer'
import Container from '../../components/container/container'
import PortalCard from '../../components/portal-card/portalCard'
import Select from 'react-select'
import request from '../../helpers/request'
import endpoints from '../../helpers/endpoints'
import Modal from 'react-modal'
import { withRouter } from 'react-router-dom'

export default withRouter(class LoginPage extends Component {
    constructor(props) {
        super(props);

        this.state = {
            error: false,
            new_user: {
                name: '',
                email: '',
                userType: 'Agent',
                company_id: ''
            },
            companies: [],
            errors: {}
        }
    }

    componentDidMount = () => {
        request(true).get(endpoints.ADMIN_COMPANIES_INDEX).then(e => {
            this.setState({
                companies: e.data.data.map(item => ({
                    value: item.id,
                    label: item.name
                })),
                new_user: {
                    ...this.state.new_user,
                    company_id: e.data.data[0].id
                }
            });
        })
    }

    closeModal = () => {
        this.setState({
            modalIsOpen: false,
        })
    }

    openModal = () => {
        this.setState({
            modalIsOpen: true,
        })
    }

    updateNewUserFormValue = (e) => {
        this.setState({
            new_user: {
                ...this.state.new_user,
                [e.target.name]: e.target.value
            }
        })
    }

    updateUserType = (e) => {
        this.setState({
            new_user: {
                ...this.state.new_user,
                userType: e.value
            }
        })
    }

    newUserFormSubmit = () => {
        request(true).post(endpoints.ADMIN_COMPANIES_USER, {
            ...this.state.new_user,
        }).then(r => {
            this.setState({
                new_user: { name: '', email: '', userType: 'Agent'}
            });
            this.props.history.push(`/admin/portal/users/${r.data.user_id}`)
        }).catch(r => {
            if(r.response.data.error == "User email already in use") {
                window.alert("User email already in use");
                this.setState({
                    new_user: { name: '', email: '', userType: 'Agent' }
                });
            } else {
                this.setState({
                    errors: r.response.data.errors
                });
            }
        });
    }

    render() {
        return (
            <div className="grid grid-gap-20">
                <Navigation />
                <Container>
                    <p className="typo-title">Platform</p>
                    <div className="grid grid-columns-4 grid-gap-20">
                        <PortalCard title="Companies" icon="fal fa-building" description="Manage Companies" link={'/admin/portal/companies'} available={true}/>
                        <PortalCard title="Products" icon="fal fa-box" description="Manage Platform Products" link={'/admin/portal/products'} available={true}/>
                        <PortalCard title="Data Filters" icon="fal fa-filter" description="Manage Platform Data Filters" link={'/admin/portal/filters'} available={true}/>
                        <PortalCard title="Data Groupings" icon="fal fa-ellipsis-h" description="Manage Platform Data Groupings" link={'/admin/portal/groupings'} available={true}/>
                        <PortalCard title="Tutorials" icon="fa-regular fa-info" description="Manage tutorials" link={'/admin/portal/company-select/tutorials'} available={true} />
                        <PortalCard title="Batch Integration" icon="fa-regular fa-circle-nodes" description="Configure batch processes" link={'/admin/portal/company-select/batch-processes'} available={true} />
                    </div>
                    <p className="typo-title">Admin</p>
                    <div className="grid grid-columns-4 grid-gap-20">
                        <PortalCard title="Admin" icon="fal fa-book" description="Manage Platform Administrators" link={'/admin/portal/users'} available={true}/>
                        <PortalCard title="Branding" icon="fal fa-palette" description="Manage Branding Configurations" link={'/admin/portal/branding'} available={true}/>
                        <PortalCard title="Audits" icon="fal fa-clipboard" description="View Access Audits" link={'/admin/portal/audits'} available={true}/>
                        <PortalCard title="Users" icon="fa-regular fa-user" description="Add new users" link={'#'} onClick={this.openModal}  available={true}/>
                        <PortalCard title="Translations" icon="fa-regular fa-scroll" description="Manage Translations" link={'/admin/portal/company-select/translate'} available={true} />
                        <PortalCard title="Usage" icon="fa-regular fa-calendar-days" description="See usage over time" link={'/admin/portal/usage'} available={true} />
                        <PortalCard title="Purchases" icon="fa-regular fa-cash-register" description="View Purchases" link={'/admin/portal/purchases'} available={true} />
                    </div>
                </Container>

                <Modal
                        style={{ overlay: { backgroundColor: 'rgba(14, 14, 14, 0.55)' } }}
                        className="card"
                        isOpen={this.state.modalIsOpen}
                        onRequestClose={this.closeModal}
                        contentLabel="Add A User"
                        appElement={document.getElementById('company-page')}
                    >
                    <div className="grid grid-gap-15">
                            <div className="form-row">
                                <label htmlFor="name">Name:</label>
                                <input onChange={this.updateNewUserFormValue} name="name" placeholder="Name" className="input" type="text" value={this.state.new_user?.name ?? ''} required/>
                                { this.state.errors?.name ? this.state.errors.name.map((value, index) => {
                                    return (
                                        <p className="error" key={index}>
                                            { value }
                                        </p>
                                    )
                                }) : <></>}
                            </div>
                            <div className="form-row">
                                <label htmlFor="email">Email:</label>
                                <input onChange={this.updateNewUserFormValue} name="email" placeholder="Email" className="input" type="text" value={this.state.new_user?.email ?? ''} required/>
                                { this.state.errors?.email ? this.state.errors.email.map((value, index) => {
                                    return (
                                        <p className="error" key={index}>
                                            { value }
                                        </p>
                                    )
                                }) : <></>}
                            </div>
                            <div className="form-row">
                                <label htmlFor="user_type">User Type:</label>
                                <Select name="user_type" onChange={((event) => this.updateUserType(event))} options={[{value: 'Agent', label: 'Agent'}, {value: 'Team Leader', label: 'Team Leader'}]} defaultValue={{value: 'Agent', label: 'Agent'}} />
                                { this.state.errors?.company ? this.state.errors.company.map((value, index) => {
                                    return (
                                        <p className="error" key={index}>
                                            { value }
                                        </p>
                                    )
                                }) : <></>}
                            </div>
                            <button onClick={this.newUserFormSubmit} className="button background-primary colour-white">Create</button>
                            <p className="text-center">{ this.state.message }</p>
                        </div>
                    </Modal>
                <Footer />
            </div>
        )
    }
})
