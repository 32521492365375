import React, { Component } from 'react'
import Navigation from '../components/navigation/navigation'
import PortalCard from '../components/portal-card/portalCard'
import WelcomeText from '../components/welcome-text/welcomeText'
import Container from '../components/container/container'
import Footer from '../components/footer/footer'
import request from '../helpers/request'
import endpoints from '../helpers/endpoints'

export default class PortalPage extends Component {

    constructor(props) {
        super(props);

        this.state = {
            products: []
        }
    }

    componentDidMount() {
        request(true).get(endpoints.PRODUCTS).then(r => {
            this.setState({
                products: r.data
            });
        }).catch(r => {
            console.error(r);
        });
    }

    render() {
        return (
            <div className="grid grid-gap-15">
                <Navigation />
                <WelcomeText />
                <Container>
                    <div className="grid grid-columns-4 grid-portal grid-gap-30 margin-top-25">
                        { this.state.products.map(product => {
                            return (
                                <PortalCard floatHouse={product.enable_house_icon} key={product.id} title={product.name} icon={product.icon} description={product.description} link={product.frontend_path} available={product.available}/>
                            )
                        })}
                    </div>
                </Container>
                <Footer />
            </div>
        )
    }
}
