import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

export default function Dropzone(props) {
    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        props.onDrop(acceptedFiles, rejectedFiles);
    }, [props]);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop, accept: ".csv, .txt, .xlsx" });

    return (
        <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} multiple={true}/>
            {isDragActive ? (
                <p>Drop the file here ...</p>
            ) : (
                    <p>Drop file here, or click to select a file</p>
                )}
        </div>
    );
}
