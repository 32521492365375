import { Tooltip } from "@material-ui/core";
import React from "react";
import { useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Text,
  XAxis,
  YAxis,
} from "recharts";
import DataGroupings from "../components/data-groupings/data-groupings";
import ProductChartInfo from "../components/product-chart-info/product-info-chart";
import request from "../helpers/request";
import GenericDataRow from "./GenericDataRow";
const localised = Intl.NumberFormat("en-GB");

export const SharedChartColours = [
  "#445469",
  "#33d1ad",
  "#0d73b2",
  "#f19a14",
  "#d13358",
  "#b24c0d",
  "#DFBE99",
  "#C879FF",
  "#DEC5E3",
  "#0D1821",
  "#F9CB40",
];

const LoadingForGraphTable = () => {
  return (
    <div style={{justifyContent: 'center', alignItems: 'center', display: 'flex', margin: '0 auto'}}>
      <i className="fas fa-spinner fa-pulse fa-4x colour-gray"></i>
    </div>
  );
};

export default function GenericDataChart({
  groupingsEndpoint,
  defaultGroupBy = null,
  totalColumns = 1,
  data = null,
  dataReady = false,
  selectedGroupBy,
  setSelectectGroupBy,
  displayModeInitial = "graph",

  sizes = {
    chartContainer: 625,
    chart: 610,
    chartXAxisFontSize: 12,
    wrapperStyle: {
      paddingTop: "25px",
    }
  },

  customBarTick,
  xAxisProps = {},

  overrideTableHeader,
  hideLegend = false
}) {
  const [groupings, setGroupings] = useState([]);

  const [displayMode, setDisplayMode] = useState(displayModeInitial);

  const totals = useMemo(() => {
    // All Columns and then total column
    if (!data) return []
    const _totals = new Array(Object.keys(data.columns).length + 1).fill(0)

    Object.keys(data.data).map(row => {
      data.data[row].map((column, _i) => {
        _totals[_i] += column;
        _totals[_totals.length-1] += column;
      })
    })

    return _totals
  }, [data]);

  useEffect(() => {
    request(true).get(groupingsEndpoint).then(e => {
      setGroupings(e.data)
    })
  }, [])

  return (
    <div>
      <div className="table-format" style={{display: 'grid', gridTemplateColumns: 'auto 200px', gap: 15, marginBottom: 15}}>
        <div className={`data-row group-list ${selectedGroupBy}-active`}>
          <GenericDataRow title={'Group by:'}>
            <DataGroupings
              group={selectedGroupBy}
              groupings={groupings}
              setGroup={setSelectectGroupBy}
            />
          </GenericDataRow>
        </div>
        <div className="data-row display-type">
          <select
            onChange={(_) => setDisplayMode(_.target.value)}
            value={displayMode}
            style={{
              width: "100%",
              height: "100%",
              border: "none",
              backgroundColor: "white",
              paddingLeft: "10px",
            }}
          >
            <option value="table">Table</option>
            <option value="graph">Graph</option>
          </select>
          <ProductChartInfo path={window.location.pathname} />
        </div>
      </div>
      <div className={`vcv-table-or-graph using-${displayMode}`} style={{minHeight: sizes.chartContainer}}>
        {!dataReady ? (
          <LoadingForGraphTable />
        ) : (
          <>
            {displayMode === "table" ? (
              <table className="table borders">
                <thead>
                  <tr>
                    <th></th>
                    <th colSpan={Object.keys(data.columns).length + 1} style={{ borderTopRightRadius: "5px" }}>
                      {overrideTableHeader ? overrideTableHeader : data.defaultGroup.replace('_', ' ')}
                    </th>
                  </tr>
                  <tr>
                    {
                      <>
                        <th>
                          {selectedGroupBy
                            ? selectedGroupBy.replaceAll("_", " ")
                            : ""}
                        </th>
                        {
                          data.graphColumns.map(_gk => _gk.__columnName).map(a => {
                            return (
                              <th>
                                {a}
                              </th>
                            )
                          })
                        }
                      </>
                    }
                    <th className="no-radius bold">Total</th>
                  </tr>
                </thead>
                <tbody>
                  {data.keys.map((item) => {
                    let total = 0

                    for (let _totalIndex = 0; _totalIndex < Object.keys(data.columns).length; _totalIndex++) {
                      total += data.data[item][_totalIndex]
                    }

                    // data.data[item].forEach((totalRow, i) => {
                    //   totals[i] += data.data[item][i]
                    // })
                    // totals.push(total);

                    return (
                       item === "N/A" ?
                          <></>
                        :
                          <tr key={`summary-data-${Math.random()}`}>
                            <td>{item !== "" ? item : "Null"}</td>
                              {
                                new Array(data.graphColumns.map(_gk => _gk.__columnName).length).fill(0).map((_, i) => {
                                  return (
                                    <td>{localised.format(data.data[item][i])}</td>
                                  )
                                })
                              }
                            <td className="bold">{localised.format(total)}</td>
                          </tr>
                    );
                  })}
                  <tr key={`summary-data-${Math.random()}`} className="bold">
                    <td>Total</td>
                    {
                      new Array(data.graphColumns.map(_gk => _gk.__columnName).length + 1).fill(0).map((_, i) => {
                        return (
                          <td>{localised.format(totals[i])}</td>
                        )
                      })
                    }
                  </tr>
                </tbody>
              </table>
            ) : (
              <ResponsiveContainer height={sizes.chart}>
                <BarChart data={data.graphColumns} barSize={50}>
                  { hideLegend ?
                    <></> 
                  :
                    <Legend iconType="circle" iconSize={11} margin={20} 
                    wrapperStyle={sizes.wrapperStyle} />
                  }
                  <XAxis {...xAxisProps} interval={0} tick={customBarTick ? customBarTick : {fontSize: sizes.chartXAxisFontSize}} dataKey="__columnName" />
                  <YAxis
                    type="number"
                    allowDecimals={false}
                    tick={{ fontSize: 10 }}
                    tickFormatter={(item) => {
                      return localised.format(item);
                    }}
                  />
                  <CartesianGrid />
                  {data.keys.map((item, index) => {
                    return (
                      <Bar
                        key={`graph_bar_${item}`}
                        dataKey={item}
                        fill={SharedChartColours[index % SharedChartColours.length]}
                        stackId={'a'}
                      />
                    );
                  })}
                  <Tooltip
                    cursor={true}
                    formatter={(value, name, props) => {
                      return localised.format(value);
                    }}
                  />
                </BarChart>
              </ResponsiveContainer>
            )}
          </>
        )}
      </div>
    </div>
  );
}
